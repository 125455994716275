import { executeRoute } from '~/modules/core/utils/routeUtils';
import { NotificationActionInfo } from '~/plugins/notificationManager/_types';
import { confirmTimeComplianceTasksIssues } from '~/modules/tasks/services/issues';
import { Notify } from 'quasar';
import { t } from '~/plugins/i18n';
import { TasksTrackingManageTab } from '~/modules/tasks/enums/_types';
import { NotificationInfo } from '~/modules/core/api/notifications/_types';

export enum NotificationActionTasksKey {
    Project = 'TasksProject',
    Issue = 'TasksIssue',
    IssueComment = 'TasksIssueComment',
    IssueTimeCompliance = 'TasksIssueTimeCompliance',
    TimeRecordApproval = 'TasksTimeRecordApproval',
}

export const getActions = (): NotificationActionInfo[] => {
    const confirmTimeCompliance = async (id: number | null, isCompliant: boolean): Promise<boolean> => {
        if (id == null) return false;

        const { isSuccess } = await confirmTimeComplianceTasksIssues(id, isCompliant);

        if (isSuccess) {
            Notify.create({
                group: 'tasks-time-compliance',
                type: 'positive',
                message: t(`tasks.notify.timeComplianceSuccess.${isCompliant ? 'yes' : 'no'}`),
            });
        }

        return isSuccess;
    };

    return [
        {
            group: NotificationActionTasksKey.Project,
            actionKey: NotificationActionTasksKey.Project,
            label: `notifications.actions.${NotificationActionTasksKey.Project}.actionTitle`,
            canExecuteFn: (info: NotificationInfo) => info.RouteParams != null,
            executeFn: async (info: NotificationInfo) => {
                if (info.RouteParams == null) return false;

                return await executeRoute({
                    name: 'tasks-id',
                    params: info.RouteParams,
                });
            },
        },
        {
            group: NotificationActionTasksKey.Issue,
            actionKey: NotificationActionTasksKey.Issue,
            label: `notifications.actions.${NotificationActionTasksKey.Issue}.actionTitle`,
            canExecuteFn: (info: NotificationInfo) => info.RouteParams != null,
            executeFn: async (info: NotificationInfo) => {
                if (info.RouteParams == null) return false;

                return await executeRoute({
                    name: 'tasks-issues-id',
                    params: info.RouteParams,
                });
            },
        },
        {
            group: NotificationActionTasksKey.IssueComment,
            actionKey: NotificationActionTasksKey.IssueComment,
            label: `notifications.actions.${NotificationActionTasksKey.IssueComment}.actionTitle`,
            canExecuteFn: (info: NotificationInfo) => info.RouteParams != null,
            executeFn: async (info: NotificationInfo) => {
                if (info.RouteParams == null) return false;

                return await executeRoute({
                    name: 'tasks-issues-id',
                    params: {
                        id: info.RouteParams.issue_id,
                    },
                    hash: '#comment_' + info.RouteParams.id,
                });
            },
        },
        {
            group: NotificationActionTasksKey.TimeRecordApproval,
            actionKey: NotificationActionTasksKey.TimeRecordApproval,
            label: `notifications.actions.${NotificationActionTasksKey.TimeRecordApproval}.actionTitle`,
            executeFn: async () => {
                return await executeRoute({
                    name: 'tasks-tracking-manage-section',
                    params: {
                        section: TasksTrackingManageTab.Approve,
                    },
                });
            },
        },
        {
            group: NotificationActionTasksKey.IssueTimeCompliance,
            actionKey: `${NotificationActionTasksKey.IssueTimeCompliance}Yes`,
            label: `notifications.actions.${NotificationActionTasksKey.IssueTimeCompliance}Yes.actionTitle`,
            textColor: 'positive',
            canExecuteFn: (info: NotificationInfo) => info.RouteParams != null,
            executeFn: async (info: NotificationInfo) => {
                if (info.RouteParams == null) return false;

                return await confirmTimeCompliance(+info.RouteParams.id, true);
            },
        },
        {
            group: NotificationActionTasksKey.IssueTimeCompliance,
            actionKey: `${NotificationActionTasksKey.IssueTimeCompliance}No`,
            label: `notifications.actions.${NotificationActionTasksKey.IssueTimeCompliance}No.actionTitle`,
            textColor: 'negative',
            canExecuteFn: (info: NotificationInfo) => info.RouteParams != null,
            executeFn: async (info: NotificationInfo) => {
                if (info.RouteParams == null) return false;

                return await confirmTimeCompliance(+info.RouteParams.id, false);
            },
        },
    ];
};
